import React from "react";
import moment from "moment";
import * as _ from "lodash";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@mui/material/styles";
import {useLocation} from "react-router-dom";
import {ContentState, convertFromRaw, EditorState} from "draft-js";

export const valueGetterDate = (params) => {
    return niceDateTime(params.value, 'date', '')
}

export const valueGetterDatetime = (params) => {
    return niceDateTime(params.value, 'datetime', '')
}


export function niceDateTime(datetime, format = "datetime", message = "n.v.t.") {
    if (!datetime) return message;
    if (format === "date") return moment(datetime).format("DD-MM-YYYY");
    if (format === "time") return moment(datetime).format("HH:mm");
    if (format === "datetime") return moment(datetime).format("DD-MM-YYYY HH:mm");
    return moment(datetime).format(format)
}

export function getToday() {
    let date = new Date()
    date.setUTCHours(0)
    date.setUTCMinutes(0)
    date.setUTCSeconds(0)
    date.setUTCMilliseconds(0)
    return date
}


export function niceUserName(user) {
    if (user) {
        const {prename, insertion, surname} = user
        return `${prename} ${insertion ? insertion + ' ' : ''} ${surname}`
    }
    return ''
}


export function implodeUsers(users) {
    return implodeOn(users, 'surname')
}

export function implodeOn(items, on = 'title') {
    return _.map(items, on).join(', ')
}

export function implodeAuthUsers(auths) {
    return auths.map((auth => {
        return auth.user.surname
    })).join(', ')
}

export function monthToText(month) {
    const months = [
        'januari', 'februari', 'maart', 'april', 'mei', 'juni',
        'juli', 'augustus', 'september', 'oktober', 'november', 'december'
    ]
    return months[month]
}


export function normalizeOptions(options, idName, labelName) {
    let nOptions = [];
    for (let option of options) {
        nOptions.push({
            id: option[idName],
            label: _.reduce(_.values(_.pick(option, labelName)), (sum, val) => {
                return sum + (val ? `${val} ` : '')
            }, ''),
        })
    }
    return nOptions
}

export function getAttrValue(attr, element, type, def) {
    const attribute = _.find(attr, ['title', element])
    if (attribute) {
        return attribute[`value${attribute.valuetype}`]
    }
    return def
}


export function getInitialValuesFromAttributes(attrs) {
    let vals = {}
    for (let attr of attrs) {
        vals[attr.title] = attr[`value${attr.valuetype}`]
    }
    return vals
}

export const stripTypenameAndId = values => {
    return JSON.parse(JSON.stringify(values), (key, value) => {
        return (key === '__typename' || key === 'id') ? undefined : value
    })
}

export const nl2br = t => {
    return t.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    })
}


export const errorHandler = (apolloError, snackbar) => {

    if (apolloError.message) {
        snackbar(apolloError.message, {variant: 'error'})
    } else {
        snackbar('andere error', {variant: 'error'})
    }

    return
    const {graphQLErrors, networkError} = apolloError
    if (graphQLErrors)
        graphQLErrors.map(({message, locations, path}) => {
                console.log(message)
                snackbar(message, {variant: 'error'})
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )
                return null
            }
        );
    else if (networkError) {
        console.log(`Netwerk fout: ${networkError}`)
        snackbar(networkError, {variant: 'error'})
    } else {
        console.log('andere error')
    }
}


export const mapFormChildren = (children) => {
    let response = []

    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if (child.props.children) {
                response = [...response, ...mapFormChildren(child.props.children)]
            } else {
                response.push(child);
            }
        }
    });

    return response
}


export const abstractMessage = message => {
    return `*****************\n${message.text}`
}

export function __n(v) {
    return v ? v : ''
}


export function userHasActivity(user, activityId, activities) {
    for (let tActivity of activities) {
        if (tActivity.activity.id === activityId && tActivity.user.id === user.id) {
            return true
        }
    }
    return false
}


export function userHasProfile(user, profileId) {
    for (let profile of user.profiles) {
        if (profile.id === profileId) return true
    }
    return false
}


export function filterUsersOnProfile(profileId, users) {
    let targetUsers = [];
    for (let user of users) {
        if (userHasProfile(user, profileId)) {
            targetUsers.push(user)
        }
    }
    return targetUsers
}

export function filterUsersActivityFromDay(activityId, users, activities, usersWithActivity = true) {
    let rUsers = [];
    for (let user of users) {
        const rUserHasActivity = userHasActivity(user, activityId, activities);
        if ((rUserHasActivity && usersWithActivity) || (!rUserHasActivity && !usersWithActivity)) {
            rUsers.push(user)
        }
    }
    return rUsers
}

export function filterDayActivityFromDay(activityId, activities) {
    let rDayActivities = [];
    for (let activity of activities) {
        if (activity.activity.id === activityId) {
            rDayActivities.push(activity)
        }
    }
    return rDayActivities
}

export function getUsersFromActivity(activityId, activities) {
    for (let activity of activities) {
        if (activity.id === activityId) {
            return activity.profile.users
        }
    }
    return []
}

export function getUsersFromDayActivities(dayActivities) {
    let users = [];
    for (let dayActivity of dayActivities) {
        users.push(dayActivity.user)
    }
    return users
}

export function userHasDayActivity(userId, activityId, dayActivities) {
    for (let dayActivity of dayActivities) {
        if (dayActivity.user.id === userId && dayActivity.activity.id === activityId) return dayActivity
    }
    return null
}


export function userList(users) {
    return (
        <List dense>
            {users.map(user => (
                <ListItem key={user.id}>
                    <ListItemText
                        primary={niceUserName(user)}
                    />
                </ListItem>
            ))}
        </List>
    )
}


export function niceFrequency(f) {
    const freqs = frequencies();
    for (let freq of freqs) {
        if (freq.id === f) return freq.title
    }
    return 'onbekend'
}

export function frequencies() {
    return [
        {id: 'YEAR', title: 'jaarlijks'},
        {id: 'HALF_YEAR', title: 'halfjaarlijks'},
        {id: 'QUARTER', title: 'elk kwartaal'},
        {id: 'MONTH', title: 'maandelijks'},
        {id: 'WEEK', title: 'wekelijks'},
        {id: 'DAY', title: 'dagelijks'},
        {id: 'HOUR', title: 'elk uur'},
        {id: 'NONE', title: 'niet van toepassing'}
    ]
}

export const filterStockitems = (data) => {
    let nData = {
        received: [],
        start: [],
        almost_expired: [],
        expired: [],
        ended: [],
    };

    for (let d of data) {
        if (d.end !== null) {
            nData.ended.push(d);
            continue
        }
        if (d.start !== null) {
            nData.start.push(d)
        } else {
            nData.received.push(d)
        }
        if (d.expiration === null) continue;
        let exp = moment(d.expiration);
        if (moment().isAfter(exp)) {
            nData.expired.push(d);
            continue
        }
        if (moment().isBetween(exp.subtract(10, 'days'), exp)) {
            nData.almost_expired.push(d)
        }
    }

    return nData
};

export const sortFindings = (findings, findingtypes) => {
    let nData = [];
    for (let findingtype of findingtypes) {
        nData.push({id: findingtype.id, title: findingtype.title, items: []})
    }

    for (let finding of findings) {
        let fT = _.find(nData, ['id', finding.type.id]);
        fT.items.push(finding)
    }

    return nData

};


export const findingsIsoElements = (findings, isoelements) => {

    let nData = [];
    for (let isoelement of isoelements) {
        nData.push({
            id: isoelement.id,
            title: isoelement.title,
            paragraph: isoelement.paragraph,
        })
    }


    return nData

};

export function useLocationQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const acceptationtestCompleted = (stockitem) => {
    if (stockitem.stock.acceptationtest) {
        return stockitem.acceptationtest && stockitem.acceptationtest.finished !== null
    }
    return null
};

export const makeValidationSummary = (validation) => {
    if (!validation) return null;

    const classes = makeStyles({
        green: {
            color: "#00FF00",
        },
        red: {
            color: "#FF0000",
        }
    })();

    return (
        <p>
            <span sx={validation.description ? classes.green : classes.red}> O </span>
            <span sx={validation.criteria ? classes.green : classes.red}> C </span>
            <span sx={validation.method ? classes.green : classes.red}> M </span>
            <span sx={validation.result ? classes.green : classes.red}> R </span>
            <span sx={validation.conclusion ? classes.green : classes.red}> C </span>
        </p>
    )
};

export function useURLQueries() {
    return new URLSearchParams(useLocation().search);
}

export function isObject(val) {
    if (val === null) {
        return false;
    }
    return ((typeof val === 'object'));
}

export function IsJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function getStickerClickItem(IdObjectArray, type) {
    let text = ''
    for (let IdObject of IdObjectArray) {
        text += '^II^TS001^ONBarcode100h^DI00h00hA' + type + '_' + IdObject.id + '^FF'
    }

    const element = document.createElement("a");
    const file = new Blob([text], {type: 'text/escp'});
    element.href = URL.createObjectURL(file);
    element.download = "print.escp";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export const getAbsentStatus = (userId, absents) => {
    for (let absent of absents) {
        if (absent.user.id === userId) {
            return {
                absentId: absent.id,
                absentreasonId: absent.absentreason.id,
                title: absent.absentreason.title
            }
        }
    }
    return {
        absentId: null,
        absentreasonId: null,
        title: 'aanwezig'
    }
}

export const formatFormHelperText = texts => {
    return (
        <List dense disablePadding>
            {texts.map((text, index) => (
                <ListItem key={index}>
                    <ListItemText
                        primary={text}
                    />
                </ListItem>
            ))}
        </List>
    )
}

export const formatSortBy = o => {
    if (!o || o.length === 0) return undefined
    return o.map(item => {
        return {[item.field]: item.sort}
    })
}

export const createWhereClause = (searchText, searchFields) => {
    if (searchText === '' || searchText === null || searchText === undefined || searchText.length === 0 || searchFields.length === 0) return undefined
    if (searchFields.length > 1) {
        return {
            OR: searchFields.map(searchField => {
                return {[searchField]: {contains: searchText}}
            })
        }
    }
    return {
        [searchFields[0]]: {contains: searchText}
    }

}
export const createDateFilter = (searchDateFrom, searchDateTo, searchFields) => {
    const items = {
        from: searchDateFrom !== '' && searchDateFrom !== null && searchDateFrom !== undefined,
        to: searchDateTo !== '' && searchDateTo !== null && searchDateTo !== undefined,
    }
    if ((items.from === false && items.to === false) || searchFields.length === 0) return undefined

    return {
        OR: searchFields.map(searchField => {
            if (items.from !== false && items.to !== false) {
                return {
                    AND: [
                        {[searchField]: {gte: searchDateFrom}},
                        {[searchField]: {lte: searchDateTo}}
                    ]
                }
            }
            if (items.from !== false) {
                return {[searchField]: {gte: searchDateFrom}}
            }
            if (items.to !== false) {
                return {[searchField]: {lte: searchDateTo}}
            }

            return undefined
        })
    }
}

export const authModelTitle = (auth) => {
    try {
        switch (auth.model) {
            case "slidesending":
            case "audit":
                return auth[auth.model].title
            case "finding":
                return auth.finding.title
            case "qualitydashboard":
                return `Kwartaal ${auth.qualitydashboard.quarter} (${auth.qualitydashboard.managementreview.year})`
            case "managementreview":
                return `MR ${auth.managentreview.year}`
            case "validation":
                return `Validatie ${auth.validation.maintenancedone.maintenancetype.title} bij ${auth.validation.maintenancedone.device.title}`
            default:
                return "onbekend"
        }
    } catch (e) {
        return `error - ${auth.id}`
    }
}


export const authModelName = (auth) => {
    switch (auth.model) {
        case "slidesending":
            return "Rondzending"
        case "audit":
            return "Audit"
        case "finding":
            return auth.finding.findingtype.title
        case "qualitydashboard":
            return "Kwartaalbeoordeling"
        case "managementreview":
            return `Managementreview`
        case "validation":
            return `Validatie`

        default:
            return "onbekend"
    }
}

export const authModelLink = (auth) => {
    if (auth.model === "validation") {
        return `/maintenancedones/view/${auth.validation.maintenancedone.id}`
    }
    return `/${auth.model}s/view/${auth[auth.model].id}`
}

export function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export function truncateDraft(raw, charCount = 50) {
    console.log("truncateDraft")
    const editorState = EditorState.createWithContent(convertFromRaw(raw));

    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();

    let index = 0;
    let currentLength = 0;
    let isTruncated = false;
    const truncatedBlocks = [];

    while (!isTruncated && blocks[index]) {
        const block = blocks[index];
        const length = block.getLength();
        if (currentLength + length > charCount) {
            isTruncated = true;
            const truncatedText = block
                .getText()
                .slice(0, charCount - currentLength);
            const state = ContentState.createFromText(`${truncatedText}...`);
            truncatedBlocks.push(state.getFirstBlock());
        } else {
            truncatedBlocks.push(block);
        }
        currentLength += length + 1;
        index++;
    }

    if (isTruncated) {
        const state = ContentState.createFromBlockArray(truncatedBlocks);
        return EditorState.createWithContent(state);
    }

    return editorState;
};

export const riskBgColor = (gravity, repeat) => {
    const score = riskScore(gravity, repeat)

    if (score >= 15) return "red"
    if (score >= 9 && score < 15) return "orange"
    if (score >= 5 && score < 9) return "yellow"
    if (score <= 4 && score >= 1) return "green"
    return "grey"
}

export const continueRisk = (risk) => {
    const score = riskScore(risk.gravity, risk.repeat)
    if (score < 9 && !risk.critical) return false
    return risk.controllable ? false : !risk.detectable
}

export const riskScore = (gravity, repeat) => {
    return gravity * repeat
}