import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";
import {SnackbarProvider} from "notistack";
import {BrowserRouter} from "react-router-dom";
import {createUploadLink} from 'apollo-upload-client';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {LocalizationProvider} from "@mui/lab";
import AuthProvider from "./View/Context/AuthProvider";

const client = new ApolloClient({
    credentials: 'include',
    link: createUploadLink({
        uri: 'https://graphql.quarpa.nl/graphql',//'http://localhost:4000/graphql', //process.env.NODE_ENV === 'development' ? 'http://localhost:4000/graphql' : 'https://graphql.quarpa.nl/graphql',
        credentials: 'include'
    }),
    cache: new InMemoryCache({
        //addTypename: false
    }),
    connectToDevTools: true
})


const theme = createTheme({
    palette: {
        primary: {
            //main: '#1e88e5',
            //main: '#ba0e0e',
            main: '#19457c',
            contrastText: '#eeeeee'
        }, // Purple and green play nicely together.
        secondary: {
            main: '#a1b3d3',
            contrastText: '#eeeeee'
        }, // This is just green.A700 as hex.
    },
});


ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <SnackbarProvider
                        maxSnack={2}
                        anchorOrigin={{horizontal: "right", vertical: "top"}}
                    >
                        <AuthProvider>
                            <App/>
                        </AuthProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
