import {gql} from '@apollo/client';
import {AUTHORIZATIONS_QUERY, FILES_QUERY, USER_QUERY, USERS_ACTIVE_QUERY, USERS_QUERY} from "./constants";


export const MESSAGES_COUNT_QUERY = gql`
    query MessagesCount{
        messagesCount {
            count
        }
    }
`;

export const MESSAGE_INDEX_QUERY = gql`
    query MessageIndex($input: MessageIndexInput!){
        messageIndex (input: $input){
            inbox
            send
            archive
            messages{
                id
                subject
                seen
                from {
                    id
                    prename
                    insertion
                    surname
                }
                created
            }
        }
    }
`;

export const MESSAGE_VIEW_QUERY = gql`
    query MessageView($id: String!){
        messageView (id: $id){
            id
            subject
            seen
            from {
                id
                prename
                insertion
                surname
            }
            created
            archive
            text
        }
    }
`;


export const PROFILE_INDEX_QUERY = gql`
    query Profiles ($take: Int, $skip: Int, $where: ProfileWhereInput, $orderBy: [ProfileOrderByInput!]){
        profiles(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            absentMarker
        }
    }
`;


export const PROFILE_EDIT_QUERY = gql`
    query ProfileEdit ($where: ProfileWhereUniqueInput!) {
        profile (where: $where){
            id
            title
            menuitems {
                id
            }
        }
        menuitems{
            id
            title
        }

    }
`;
export const NOTIFICATION_EDIT_QUERY = gql`
    query NotificationEdit {
        notificationEdit {
            meetingtodos{
                id
                date
                meetingtype{
                    id
                    title
                }
            }
            alerts{
                id
                title
                date
                finding{
                    id
                    title
                }
            }
            authorizations{
                id
                model
                authrole{
                    id
                    title
                }
                slidesending{
                    id
                    title
                }
                audit{
                    id
                    title
                }
                finding{
                    id
                    title
                    findingtype{
                        id
                        title
                    }
                }
                managementreview{
                    id
                    year
                }
                qualitydashboard{
                    id
                    quarter
                    managementreview{
                        id
                        year
                    }
                }
                validation{
                    id
                    maintenancedone{
                        id
                        date
                        maintenancetype{
                            id
                            title
                        }
                        device{
                            id
                            title
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_ONE_QUARPA_MUTATION = gql`
    mutation CreateOneQuarpa($data: QuarpaCreateInput!) {
        createOneQuarpa(data: $data){
            id
            title
        }
    }
`;

export const CREATE_ONE_RISKGROUP_MUTATION = gql`
    mutation CreateOneRiskgroup($data: RiskgroupCreateInput!) {
        createOneRiskgroup(data: $data){
            id
            title
        }
    }
`;
export const CREATE_ONE_RISK_MUTATION = gql`
    mutation CreateOneRisk($data: RiskCreateInput!) {
        createOneRisk(data: $data){
            id
            act
            created
        }
    }
`;

export const CREATE_ONE_PROFILE_MUTATION = gql`
    mutation CreateOneProfile($data: ProfileCreateInput!) {
        createOneProfile(data: $data){
            id
            title
        }
    }
`;

export const USER_EDIT_QUERY = gql`
    query UserEdit ($where: UserWhereUniqueInput!) {
        user (where: $where){
            id
            active
            prefix
            prename
            insertion
            surname
            username
            email
            profiles{
                id
                title
            }
        }
        profiles {
            id
            title
        }
    }
`;

export const ACTIONNAME_EDIT_QUERY = gql`
    query Actionname ($where: ActionnameWhereUniqueInput!) {
        actionname(where: $where){
            id
            title
            profiles{
                id
                title
            }
        }
        profiles{
            id
            title
        }
    }
`;

export const DAYABSENT_PROFILES_ABSENT_INDEX_QUERY = gql`
    query DayProfilesAbsentIndex($date: String!, $profileId: String, $usersId: [String!]!){
        singleDayEdit(date: $date){
            day{
                id
                date
                users(profileId: $profileId, usersId: $usersId){
                    id
                    prename
                    insertion
                    surname
                    dayabsent(date: $date){
                        id
                        remarks
                        absentreason{
                            id
                            title
                        }
                    }
                }
            }
            absentreasons{
                id
                title
            }

        }
    }
`


export const ACTIVITY_EDIT_QUERY = gql`
    query ActivityEdit ($where: ActivityWhereUniqueInput!) {
        activity(where: $where) {
            id
            title
            max
            profile{
                id
            }
        }
        profiles {
            id
            title
        }
    }
`;


export const ALERT_EDIT_QUERY = gql`
    query AlertEdit ($where: AlertWhereUniqueInput!) {
        alert (where: $where) {
            id
            title
            date
            users{
                id
            }
        }
        ${USERS_QUERY}
    }
`;

export const APPOINTMENT_EDIT_QUERY = gql`
    query AppointmentEdit ($where: AppointmentWhereUniqueInput!) {
        appointment(where: $where) {
            id
            title
            start
            end
            description
            location
            ${USERS_QUERY}
        }
        profiles{
            id
            title
            ${USERS_QUERY}
        }
    }
`;

export const MENUITEM_EDIT_QUERY = gql`
    query MenuitemEdit ($where: MenuitemWhereUniqueInput!) {
        menuitem(where: $where){
            id
            title
            icon
            link
            subgroup
            profiles{
                id
            }
        }
        profiles{
            id
            title
        }
    }
`;

export const ABSENT_EDIT_MULTIPLE_QUERY = gql`
    query AbsentEditMultiple {
        absentEditMultiple {
            ${USERS_QUERY}
            absentreasons{
                id
                title
            }
        }
    }
`;


export const USER_FROM_TOKEN_QUERY = gql`
    query UserFromToken{
        me{
            id
            prefix
            prename
            insertion
            surname
            username
            email
            active
            profiles {
                id
                title
            }
        }
        menuitemsList{
            id
            rang
            title
            link
            icon
            subgroup
        }
        notificationCount
    }
`;

export const STOCK_EDIT_QUERY = gql`
    query StockEdit ($id: String!, $stockitemId: String){
        stockEdit(id: $id, stockitemId: $stockitemId){
            stock{
                id
                title
                ${FILES_QUERY}
                manufacturer{
                    id
                }
                artnumberintern
                artnumberextern
                location
                minimum
                acceptationtest
                active
                automatic
            }
        }
        stockitemstatuses{
            title
            stockitems(where: {stock:{id: {equals: $id}}}){
                id
                acceptationtest{
                    id
                    description
                }
                end
                start
                received
                expiration
                lot
            }
        }

        ${USERS_ACTIVE_QUERY}
        manufacturers(where: {active: {equals: true}}) {
            id
            title
            contact
        }
    }
`;


export const STOCKITEM_EDIT_QUERY = gql`
    query StockitemEdit($id: String!) {
        stockitemEdit (id: $id){
            stockitem{
                id
                lot
                expiration
                received
                received_status
                receiver{
                    id
                    prename
                    insertion
                    surname
                }
                start
                start_status
                starter{
                    id
                    prename
                    insertion
                    surname
                }
                end
                end_status
                ender{
                    id
                    prename
                    insertion
                    surname
                }
                acceptationtest{
                    id
                    created
                    updated
                    finished
                    description
                    ${USER_QUERY}
                }
                stock{
                    id
                    title
                    acceptationtest
                }
                status{
                    id
                    title
                }
                order{
                    id
                    date
                    received
                    ${USER_QUERY}
                }
            }
            ${USERS_QUERY}
        }
    }
`;


export const ACTIVITY_INDEX_QUERY = gql`
    query Activities($take: Int, $skip: Int, $where: ActivityWhereInput, $orderBy: [ActivityOrderByInput!]){
        activities (take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            max
            profile {
                id
                title
            }
        }
        activitiesCount(where: $where, orderBy: $orderBy)
    }
`;

export const SLIDESENDING_INDEX_QUERY = gql`
    query Slidesendings($take: Int, $skip: Int, $where: SlidesendingWhereInput, $orderBy: [SlidesendingOrderByInput!]){
        slidesendings(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            created
            deadline
            finished
        }
        slidesendingsCount(where: $where)
    }
`;

export const RISKGROUP_INDEX_QUERY = gql`
    query Riskgroups($take: Int, $skip: Int, $where: RiskgroupWhereInput, $orderBy: [RiskgroupOrderByInput!]){
        riskgroups(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
        }
        riskgroupsCount(where: $where)
    }
`;

export const RISK_INDEX_QUERY = gql`
    query Risks($take: Int, $skip: Int, $where: RiskWhereInput, $orderBy: [RiskOrderByInput!]){
        risks(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            act
            process
            failure
            gravity
            repeat
            critical
            controllable
            detectable
            created
        }
        risksCount(where: $where)
    }
`;


export const GET_ONE_QUARPA_QUERY = gql`
    query Quarpa($where: QuarpaWhereUniqueInput!){
        quarpa(where: $where) {
            id
            title
            finished
            description
        }
    }
`;

export const GET_ONE_RISKGROUP_QUERY = gql`
    query Riskgroup($where: RiskgroupWhereUniqueInput!){
        riskgroup(where: $where) {
            id
            title
            created
            description
        }
    }
`;

export const GET_ONE_RISK_QUERY = gql`
    query Risk($where: RiskWhereUniqueInput!){
        risk(where: $where) {
            id
            act
            process
            failure
            gravity
            repeat
            critical
            controllable
            detectable
            created
            description
            riskgroup{
                id
                title
            }
            oorzaak
            omvang
            oplossing
            operationaliteit
            viero
        }
    }
`;

export const MANAGEMENTREVIEW_INDEX_QUERY = gql`
    query Managementreviews ($take: Int, $skip: Int, $where: ManagementreviewWhereInput, $orderBy: [ManagementreviewOrderByInput!]){
        managementreviews(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            year
            qualitydashboards{
                id
                quarter
            }
        }
    }
`;
export const MAINTENANCETYPE_INDEX_QUERY = gql`
    query MaintenancetypeIndex($filter: MaintenancetypeIndexFilterInput){
        maintenancetypeIndex (filter: $filter){
            id
            title
        }
    }
`;

export const MANUFACTURER_ADD_QUERY = gql`
    query ManufacturerAdd {
        manufacturerAdd {
            manufacturerroles{
                id
                title
            }
        }
    }
`;
export const AUDIT_ADD_QUERY = gql`
    query AuditAdd {
        auditAdd {
            users {
                id
                prename
                insertion
                surname
            }
            isoelements{
                id
                title
                paragraph
            }
        }
    }
`;

export const APPOINTMENT_ADD_QUERY = gql`
    query AppointmentAdd {
        appointmentAdd {
            ${USERS_QUERY}
        }
    }
`;

export const ACTIVITY_ADD_QUERY = gql`
    query ActivityAdd {
        activityAdd {
            profiles {
                id
                title
            }
        }
    }
`;

export const ALERT_ADD_QUERY = gql`
    query AlertAdd {
        alertAdd {
            ${USERS_QUERY}
        }
    }
`;


export const GET_ONE_SLIDESENDING_QUERY = gql`
    query Slidesending ($where: SlidesendingWhereUniqueInput!) {

        slidesending(where: $where){
            id
            title
            description
            result
            deadline
            created
            finished
            ${AUTHORIZATIONS_QUERY}
            ${FILES_QUERY}
        }

    }

`;


export const GET_MANY_FINDINGTYPES_QUERY = gql`
    query Findingtypes ($where: FindingtypeWhereInput, $findingFilter: FindingWhereInput) {
        findingtypes(where: $where) {
            id
            title
            findings(where: $findingFilter){
                id
                title
                isoelements{
                    id
                }
                findingtype{
                    id
                    title
                }
                created
                createdBy{
                    id
                    prename
                    insertion
                    surname
                }
                finished
            }
        }
    }
`;


export const SLIDESENDING_EDIT_QUERY = gql`
    query SlidesendingEdit ($id: String!, $findingFilter: FindingWhereInput) {
        slidesendingEdit (id: $id){
            slidesending{
                id
                title
                description
                result
                deadline
                created
                finished
                ${AUTHORIZATIONS_QUERY}
                ${FILES_QUERY}
            }
            findingtypes {
                id
                title
                findings(where: $findingFilter){
                    id
                    title
                    isoelements{
                        id
                    }
                    findingtype{
                        id
                        title
                    }
                    created
                    createdBy{
                        id
                        prename
                        insertion
                        surname
                    }
                    finished
                }
            }
        }
    }
`;

export const QUALITYDASHBOARD_EDIT_QUERY = gql`
    query QualitydashboardEdit ($where: QualitydashboardWhereUniqueInput!, $findingFilter: FindingWhereInput, $findingtypeFilter: FindingtypeWhereInput) {
        qualitydashboard(where: $where){
            id
            quarter
            finished
            qualitydashboarditems{
                id
                qualitydashboarditemtype{
                    id
                    title
                }
            }
            managementreview{
                id
                year
            }
            ${AUTHORIZATIONS_QUERY}
            ${FILES_QUERY}
        }
        findingtypes(where: $findingtypeFilter){
            id
            title
            findings(where: $findingFilter){
                id
                title
                createdBy{
                    id
                    surname
                    insertion
                    prename
                }
                created
            }
        }
    }
`;


export const QUALITYDASHBOARDITEM_EDIT_QUERY = gql`
    query QualitydashboarditemEdit ($id: String!, $year: Int!) {
        qualitydashboarditemEdit(id: $id){
            qualitydashboarditem {
                id
                qualitydashboarditemtype{
                    id
                    title
                }
                introduction
                evaluation
                conclusion
            }
            findinggroup{
                title
                description
                groupedfindings{
                    title
                    findings{
                        id
                        title
                        created
                        createdBy{
                            id
                            prename
                            insertion
                            surname
                        }
                        finished
                    }
                }
            }
            auditgroup{
                title
                description
                audits{
                    id
                    title
                    date
                    finished
                }
            }
            slidesendings{
                id
                title
                deadline
                finished
            }
            manufacturers{
                id
                title
                manufacturerreviews(where: {yearjudgement: {equals: true}, yearjudgementyear: {equals: $year}}){
                    id
                    date
                    description
                }
            }
        }
    }
`;


export const MANAGEMENTREVIEW_EDIT_QUERY = gql`
    query ManagementreviewEdit ($id: String!) {
        managementreviewEdit (id: $id){
            managementreview{
                id
                year
                finished
                conclusion
                qualitydashboards{
                    id
                    quarter
                    qualitydashboarditems{
                        id
                        qualitydashboarditemtype{
                            id
                            title
                        }
                        introduction
                        evaluation
                        conclusion
                    }
                }
                ${AUTHORIZATIONS_QUERY}
                ${FILES_QUERY}
            }
            ${USERS_QUERY}
            findings{
                name
                items{
                    id
                    title
                    createdBy{
                        id
                        surname
                        insertion
                        prename
                    }
                    created
                    finished
                    findingtype{
                        id
                        title
                    }
                }
            }
            qualitydashboarditemtypes{
                id
                title
            }
            findingtypes{
                id
                title
            }
        }
    }
`;


export const AUDIT_EDIT_QUERY = gql`
    query AuditEdit ($whereAudit: AuditWhereUniqueInput!) {
        audit(where: $whereAudit) {
            id
            title
            subject
            notes
            isoelements{
                id
                title
                paragraph
            }
            date
            finished
            extern
            externalUsers
            ${FILES_QUERY}
            ${AUTHORIZATIONS_QUERY}
        }
        ${USERS_QUERY}
        isoelements{
            id
            title
            paragraph
        },
        findingtypes (where: {model: {equals: "Audit"}}){
            id
            title
        }
    }
`;


export const AUTHORIZATIONS_EDIT_QUERY = gql`
    query AuthorizationEdit ($where: AuthorizationWhereUniqueInput!) {
        authorization(where: $where){
            id
            date
            authrole{
                id
                title
            }
            ${USER_QUERY}
        }
        ${USERS_QUERY}
        authroles{
            id
            title
        }
    }
`;


export const AUTHORIZATIONS_ADD_QUERY = gql`
    query AuthorizationAdd {
        authorizationAdd{
            authroles{
                id
                title
            }
            ${USERS_QUERY}
        }
    }
`;


export const FINDING_ADD_QUERY = gql`
    query FindingAdd($whereFindingtype: FindingtypeWhereUniqueInput!){
        findingtype (where: $whereFindingtype){
            id
            title
            model
            viero
        }
        isoelements{
            id
            title
        }
    }
`;

export const FINDING_EDIT_QUERY = gql`
    query Finding ($where: FindingWhereUniqueInput!){
        finding(where: $where){
            id
            title
            description
            document
            finished
            oorzaak
            omvang
            oplossing
            operationaliteit
            findingtype {
                id
                model
                title
                viero
                admitted
            }
            viero
            isoelements{
                id
            }
            createdBy{
                id
                prename
                insertion
                surname
            }
            admitted{
                id
            }
        }
        isoelements{
            id
            title
            paragraph
        }
        admitteds{
            id
            title
        }
    }
`;


export const MENUITEM_INDEX_QUERY = gql`
    query Menuitems($take: Int, $skip: Int, $where: MenuitemWhereInput, $orderBy: [MenuitemOrderByInput!]){
        menuitems (take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            rang
            title
            link
            icon
            subgroup
        }
    }
`;


export const MENUITEM_LIST_QUERY = gql`
    query MenuitemsList{
        menuitemsList {
            id
            rang
            title
            link
            icon
            subgroup
        }
    }
`;


export const USER_INDEX_QUERY_NEW = gql`
    query Users ($take: Int, $skip: Int, $where: UserWhereInput, $orderBy: [UserOrderByInput!]){
        users(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            prename
            insertion
            surname
            email
            profiles {
                id
                title
            }
        }
    }
`;

export const ACTIONNAME_INDEX_QUERY = gql`
    query Actionnames ($take: Int, $skip: Int, $where: ActionnameWhereInput, $orderBy: [ActionnameOrderByInput!]){
        actionnames(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
            id
            title
            profiles {
                id
                title
            }
        }
        actionnamesCount(where: $where, orderBy: $orderBy)
    }
`;


export const GET_MANY_DAY_REMARKS_QUERY = gql`
    query RemarksDays ($where: DayWhereInput, $orderBy: [DayOrderByInput!]){
        days(where: $where, orderBy: $orderBy){
            id
            date
            remark
        }
    }
`;

export const GET_ONE_DAY_QUERY = gql`
    query Day ($whereDay: DayWhereUniqueInput!){
        day(where: $whereDay){
            id
            date
            active
        }
    }
`;

export const GET_ONE_DATERANGE_QUERY = gql`
    query Daterange ($where: DaterangeWhereUniqueInput!){
        daterange(where: $where){
            id
            start
            end
        }
    }
`;


export const SINGLE_DAY_EDIT_QUERY = gql`
    query SingleDayEdit ($date: String!){
        singleDayEdit(date: $date){
            day{
                id
                date
                dayactivities{
                    id
                    remark
                    activity {
                        id
                        title
                    }
                    ${USER_QUERY}
                }
                remark
                active
            }
            activities{
                id
                title
                showinactive
                profile{
                    id
                    title
                    ${USERS_QUERY}
                }
            }
            audits{
                id
                title
                date
            }
            appointments{
                id
                title
                start
                end
                location
                users{
                    id
                    prename
                    insertion
                    surname
                }
            }
            meetings{
                id
                title
                date
                meetingtype {
                    id
                    title
                }
            }
            users{
                id
                prename
                insertion
                surname
                profiles {
                    id
                }
            }
        }
    }
`;

export const DEVICE_EDIT_QUERY = gql`
    query Device ($where: DeviceWhereUniqueInput!){
        device(where: $where){
            id
            title
            model
            application
            inventorynumber
            received
            inservice
            outservice
            location
            active
            manufacturer{
                id
            }
        }
        manufacturers {
            id
            title
            contact
        }
    }
`;

export const MEETING_INDEX_QUERY = gql`
    query MeetingIndex($filter: MeetingIndexFilterInput){
        meetingIndex (filter: $filter){
            meetings {
                id
                title
                date
                secure
                meetingtype {
                    id
                    title
                }
            }
            meetingtypes{
                id
                title
            }
        }
    }
`;


export const MEETING_EDIT_QUERY = gql`
    query MeetingEdit ($id: String!, $meetingtypeId: String, $date: String, $findingFilter: FindingWhereInput){
        meetingEdit(id: $id, meetingtypeId: $meetingtypeId, date: $date){
            meeting{
                id
                title
                date
                finished
                secure
                president {
                    id
                    prename
                    insertion
                    surname
                }
                secretary {
                    id
                    prename
                    insertion
                    surname
                }
                attendees {
                    id
                    prename
                    insertion
                    surname
                }
                text
                decisions{
                    id
                    description
                    date
                }
                meetingtype {
                    id
                    title
                    findingtypes{
                        id
                        title
                        findings(where: $findingFilter){
                            id
                            title
                            createdBy{
                                id
                                surname
                                insertion
                                prename
                            }
                            created
                            audit{
                                id
                                title
                            }
                            managementreview{
                                id
                                year
                            }
                            slidesending{
                                id
                                title
                            }
                            qualitydashboard{
                                id
                                quarter
                                managementreview{
                                    id
                                    year
                                }
                            }
                        }
                    }
                    meetingtodos{
                        id
                        date
                        finished
                        description
                        ${USERS_QUERY}
                    }
                }
                ${FILES_QUERY}
            }
            previousmeeting{
                id
                date
            }
            nextmeeting{
                id
                date
            }
        }
    }
`;

export const MEETINGTODO_INDEX_QUERY = gql`
    query Meetingtodos ($where: MeetingtodoWhereInput){
        meetingtodos(where: $where){
            id
            date
            finished
            description
            ${USERS_QUERY}
        }
    }
`;

export const MAINTENANCEDONE_EDIT_QUERY = gql`
    query Maintenancedone ($where: MaintenancedoneWhereUniqueInput!){
        maintenancedone(where: $where){
            id
            maintenancetype {
                id
                title
            }
            device{
                id
                title
                location
                model
            }
            description
            date
            finished
            ${USER_QUERY}
            userExternal
            validation{
                id
                created
                finished
                description
                criteria
                method
                result
                conclusion
                ${AUTHORIZATIONS_QUERY}
            }
        }
    }
`;

export const VALIDATION_EDIT_QUERY = gql`
    query Validation ($where: ValidationWhereUniqueInput!){
        validation(where: $where){
            id
            created
            finished
            description
            criteria
            method
            result
            conclusion
        }
    }
`;

export const APPOINTMENT_INDEX_QUERY = gql`
    query AppointmentIndex($filter: AppointmentIndexFilterInput){
        appointmentIndex (filter: $filter){
            id
            title
            start
            end
            location
            users{
                id
                prename
                insertion
                surname
            }
        }
    }
`;

export const DEVICE_ADD_QUERY = gql`
    query DeviceAdd {
        deviceAdd {
            manufacturers{
                id
                title
            }
        }
    }
`;

export const DEVICE_INDEX_QUERY = gql`
    query DeviceIndex($filter: DeviceIndexFilterInput){
        deviceIndex (filter: $filter){
            id
            title
            model
            application
            manufacturer {
                title
            }
        }
    }
`;

export const FINDING_INDEX_QUERY = gql`
    query FindingIndex($filter: FindingIndexFilterInput!){
        findingIndex (filter: $filter){
            items{
                id
                title
                findingtype{
                    id
                    title
                }
                createdBy{
                    prename
                    insertion
                    surname
                }
                created
                updated
            }
            findingtypes {
                id
                title
            }
        }
    }
`;

export const FINDINGTYPE_INDEX_QUERY = gql`
    query FindingtypeIndex($filter: FindingtypeIndexFilterInput){
        findingtypeIndex (filter: $filter){
            id
            title
        }
    }
`;

export const FINDINGTYPE_EDIT_QUERY = gql`
    query FindingtypeEdit ($id: String!){
        findingtypeEdit(id: $id){
            findingtype{
                id
                title
                model
                viero
            }
        }
    }
`;


export const MAINTENANCEPLAN_ADD_QUERY = gql`
    query MaintenanceplanAdd {
        maintenanceplanAdd {
            maintenancetypes {
                id
                title
            }
        }
    }
`;

export const MAINTENANCEPLAN_EDIT_QUERY = gql`
    query MaintenanceplanEdit ($id: String!){
        maintenanceplanEdit(id: $id){
            maintenanceplan {
                id
                maintenancetype {
                    id
                }
                validation
                frequency
                description
            }
            maintenancetypes {
                id
                title
            }
        }
    }
`;

export const MAINTENANCETYPE_EDIT_QUERY = gql`
    query MaintenancetypeEdit ($id: String!){
        maintenancetypeEdit(id: $id){
            maintenancetype {
                id
                title
            }
        }
    }
`;


export const MANUFACTURER_EDIT_QUERY = gql`
    query ManufacturerEdit($id: String!) {
        manufacturerEdit (id: $id){
            manufacturer {
                id
                title
                contact
                manufacturerroles{
                    id
                    title
                }
                active
                stocks{
                    id
                    title
                }
                devices{
                    id
                    title
                }

            }

            manufacturerroles{
                id
                title
            }
        }
    }
`;

export const MANUFACTURER_INDEX_QUERY = gql`
    query ManufacturerIndex($filter: ManufacturerIndexFilterInput){
        manufacturerIndex (filter: $filter){
            id
            title
            manufacturerroles {
                id
                title
            }
        }
    }
`;

export const MANUFACTURERREVIEW_EDIT_QUERY = gql`
    query ManufacturerreviewEdit ($id: String!){
        manufacturerreviewEdit(id: $id){
            manufacturerreview {
                id
                description
                date
                contact
                friendly
                accessability
                speed
                pricequality
                satisfaction
                yearjudgement
                yearjudgementyear
            }
        }
    }
`;


export const MANUFACTURER_YEAR_JUDGEMENT_EDIT_QUERY = gql`
    query ManufacturerYearJudgement($id: String!, $year: Int!) {
        manufacturerYearJudgement (id: $id, year: $year){
            manufacturer {
                id
                title
                contact
                manufacturerroles{
                    id
                    title
                }
                active
            }
            year
            data {
                contact
                friendly
                pricequality
                accessability
                satisfaction
                speed
                reviews {
                    id
                    description
                    date
                }
            }
        }
    }
`;

export const MEETING_ADD_QUERY = gql`
    query MeetingAdd{
        meetingAdd{
            users{
                id
                surname
                insertion
                prename
            }
            meetingtypes{
                id
                title
                defaultPresident{
                    id
                    prename
                    insertion
                    surname
                }
                defaultSecretary{
                    id
                    prename
                    insertion
                    surname
                }
                defaultAttendees{
                    id
                    prename
                    insertion
                    surname
                }
            }
        }
    }
`;

export const MEETINGTYPE_EDIT_QUERY = gql`
    query Meetingtype ($where: MeetingtypeWhereUniqueInput!){
        meetingtype(where: $where){
            id
            title
            findingtypes{
                id
            }
            defaultPresident{
                id
                prename
                insertion
                surname
            }
            defaultSecretary{
                id
                prename
                insertion
                surname
            }
            defaultAttendees{
                id
                prename
                insertion
                surname
            }
        }
        findingtypes{
            id
            title
        }
    }
`;

export const MEETINGTYPE_INDEX_QUERY = gql`
    query Meetingtypes($where: MeetingtypeWhereInput){
        meetingtypes (where: $where){
            id
            title
        }
    }
`;

export const MESSAGE_ADD_QUERY = gql`
    query MessageAdd ($id: String) {
        messageAdd (id: $id){
            message{
                id
                from{
                    id
                    prename
                    insertion
                    surname
                }
                subject
                created
                text
            }
            ${USERS_QUERY}
        }
    }
`;

export const PAGE_INDEX_QUERY = gql`
    query PageIndex($filter: PageIndexFilterInput){
        pageIndex (filter: $filter){
            id
            title
            text
        }
    }
`;

export const STOCK_MIRROR_QUERY = gql`
    query StockMirror{
        stockMirror {
            id
            title
            minimum
            location
            stockitems {
                id
                received
                expiration
                start
                end
            }
        }
    }
`;

export const USER_ADD_QUERY = gql`
    query UserAdd{
        userAdd{
            profiles{
                id
                title
            }
        }
    }
`;


export const MEETINGTODO_EDIT_QUERY = gql`
    query Meetingtodo ($where: MeetingtodoWhereUniqueInput!) {
        meetingtodo (where: $where){
            id
            description
            date
            finished
            ${USERS_QUERY}
        }
        profiles{
            id
            title
            ${USERS_QUERY}
        }
    }
`;


export const GET_ONE_DECISION_QUERY = gql`
    query Decision ($where: DecisionWhereUniqueInput!) {
        decision (where: $where){
            id
            description
            date
            meeting{
                id
            }
        }
    }
`;

