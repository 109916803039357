import Icon from "../Icons/Icon";
import React from "react";

export const OPTIONS_ACTIEF = [{id: true, label: 'actief'}, {id: false, label: 'inactief'}];
export const OPTIONS_PREFIX = [{id: 'heer', label: 'dhr'}, {id: 'vrouw', label: 'mw'}];
export const REPEAT_OPTIONS = [
    {id: 'n', title: 'geen herhaling'},
    {id: 'd', title: 'dagelijks'},
    {id: 'w', title: 'wekelijks'},
    {id: 'M', title: 'maandelijks'},
    {id: 'Q', title: 'elk kwartaal'},
    {id: 'y', title: 'jaarlijks'},
];
export const REPEAT_ABSENT_OPTIONS = [
    {id: 'd', label: 'dagelijks'},
    {id: 'w', label: 'wekelijks'},
    {id: '2w', label: 'tweewekelijks'},
    {id: 'M', label: 'maandelijks'},
];

export const DAYS_OPTIONS = [
    {id: '1', label: 'maandag'},
    {id: '2', label: 'dinsdag'},
    {id: '3', label: 'woensdag'},
    {id: '4', label: 'donderdag'},
    {id: '5', label: 'vrijdag'},
];

export const EMPTY_DRAFT_OBJECT = {
    "blocks": [
        {
            "key": "9c1um",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
}

export const NO_PROBLEMO_DRAFT_OBJECT = {
    "blocks": [
        {
            "key": "9c1um",
            "text": "Geen afwijkingen",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
}

export const YEARREVIEW_CONTINUE_DRAFT_OBJECT = {
    "blocks": [
        {
            "key": "9c1um",
            "text": "Gezien de beoordelingen besluiten we om de samenwerking te continueren voor het komende jaar.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        }
    ],
    "entityMap": {}
}

export const test = {
    "data": {
        "ideal": {
            "order": 1,
            "description": "iDEAL",
            "methods": [{"id": "ideal/RABONL2U", "order": 1, "name": "Rabobank"}, {
                "id": "ideal/INGBNL2A",
                "order": 2,
                "name": "ING"
            }, {"id": "ideal/ABNANL2A", "order": 3, "name": "ABN AMRO"}, {
                "id": "ideal/SNSBNL2A",
                "order": 4,
                "name": "SNS Bank"
            }, {"id": "ideal/RBRBNL21", "order": 5, "name": "RegioBank"}, {
                "id": "ideal/ASNBNL21",
                "order": 6,
                "name": "ASN Bank"
            }, {"id": "ideal/KNABNL2H", "order": 7, "name": "Knab"}, {
                "id": "ideal/TRIONL2U",
                "order": 8,
                "name": "Triodos Bank"
            }, {"id": "ideal/FVLBNL22", "order": 9, "name": "van Lanschot"}, {
                "id": "ideal/BUNQNL2A",
                "order": 10,
                "name": "Bunq"
            }, {"id": "ideal/MOYONL21", "order": 11, "name": "Moneyou"}, {
                "id": "ideal/HANDNL2A",
                "order": 12,
                "name": "Handelsbanken"
            }]
        },
        "paypal": {
            "order": 2,
            "description": "PayPal",
            "methods": [{"id": "paypal/direct", "order": 1, "name": "PayPal"}, {
                "id": "paypal/recurring",
                "order": 2,
                "name": "PayPal"
            }]
        },
        "directdebit": {
            "order": 4,
            "description": "SEPA Incasso",
            "methods": [{
                "id": "directdebit/sepa-once",
                "order": 1,
                "name": "SEPA Eenmalige Machtiging"
            }, {"id": "directdebit/sign2pay", "order": 2, "name": "Sign2pay"}, {
                "id": "directdebit/sepa-recurring",
                "order": 3,
                "name": "SEPA Doorlopende Machtiging"
            }]
        },
        "banktransfer": {
            "order": 5,
            "description": "SEPA Overboeking",
            "methods": [{"id": "banktransfer/sepa", "order": 1, "name": "SEPA Overboeking"}]
        },
        "creditcard": {
            "order": 6,
            "description": "Creditcard",
            "methods": [{"id": "creditcard/visa", "order": 1, "name": "Visa"}, {
                "id": "creditcard/mastercard",
                "order": 2,
                "name": "Mastercard"
            }]
        },
        "sofort": {
            "order": 7,
            "description": "Sofort",
            "methods": [{"id": "sofort/digital", "order": 1, "name": "Sofort"}, {
                "id": "sofort/physical",
                "order": 2,
                "name": "Sofort"
            }]
        },
        "afterpay": {
            "order": 8,
            "description": "Afterpay",
            "methods": [{"id": "afterpay/giro", "order": 1, "name": "Afterpay"}]
        },
        "bancontact": {
            "order": 3,
            "description": "Bancontact",
            "methods": [{"id": "bancontact/mrcash", "order": 1, "name": "Bancontact"}]
        },
        "ideal_qr": {
            "order": 9,
            "description": "iDEAL QR",
            "methods": [{"id": "ideal_qr", "order": 1, "name": "iDEAL QR"}]
        }
    }, "total": 9
}


export const USERS_ACTIVE_QUERY =
    `users(where: {active: {equals: true}}) {
                id
                prename
                insertion
                surname
            }`

export const USERS_QUERY =
    `users {
                id
                prename
                insertion
                surname
                active
            }`

export const USER_QUERY =
    `user {
                id
                prename
                insertion
                surname
            }`


export const FILES_QUERY =
    `files{
    id
    created
    path
    model
    modelId
    filename
    mimetype
    encoding
    ${USER_QUERY}
}`

export const AUTHORIZATIONS_QUERY = `authorizations {
                        id
                        date
                        authrole{
                            id
                            title
                        }
                        ${USER_QUERY}
                    }`

export const MANUFACTURERREVIEW_CONTACT_OPTIONS =
    [
        {id: 0, title: 'n.v.t.'},
        {id: 1, title: 'email'},
        {id: 2, title: 'telefonisch'},
        {id: 3, title: 'op locatie'},
    ]

export const MANUFACTURERREVIEW_SCORE_OPTIONS = {
    null: "niet van toepassing",
    0: "zeer slecht",
    1: "slecht",
    2: "matig",
    3: "voldoende",
    4: "goed",
    5: "zeer goed",
}

export const MANUFACTURERREVIEW_SCORE_OPTIONS_COLORS = {
    null: "#bdbdbd",
    0: "#e23b25",
    1: "#e27825",
    2: "#f0e151",
    3: "#cf841c",
    4: "#83a6ed",
    5: "#82ca9d",
}


export const DATETIME_FORMATS = {
    time: "HH:mm",
    date: "YYYY-MM-DD",
    datetime: "YYYY-MM-DD HH:mm"
}

export const PASSWORD_VALIDATOR = new RegExp("(?=^.{8,}$)(?=.*\\d)(?=.*[!@}{#$%^&*]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$");

export const drawerWidth = 240

export const ratingIcons = {
    1: {
        icon: <Icon name={'veryDissatisfiedIcon'} sx={{color: "red"}}/>,
        label: 'Zeer ontevreden',
    },
    2:
        {
            icon: <Icon name={'dissatisfiedIcon'} sx={{color: "orange"}}/>,
            label: 'Ontevreden',
        },
    3:
        {
            icon: <Icon name={'satisfiedIcon'} sx={{color: "orange"}}/>,
            label: 'Neutraal',
        },
    4:
        {
            icon: <Icon name={'satisfiedAltIcon'} sx={{color: "green"}}/>,
            label: 'Tevreden',
        },
    5:
        {
            icon: <Icon name={'verySatisfiedIcon'} sx={{color: "green"}}/>,
            label: 'Zeer tevreden',
        },
};