import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {USER_FROM_TOKEN_QUERY} from "../../ConstantsFuncs/query";
import {USER_LOGIN_LIKE_MUTATION, USER_LOGIN_MUTATION, USER_LOGOUT_MUTATION} from "../../ConstantsFuncs/mutations";
import {withApollo} from "@apollo/client/react/hoc";
import AuthContext from "./AuthContext";
import {useSnackbar} from "notistack";
import {delay, errorHandler, niceUserName} from "../../ConstantsFuncs/funcs";
import {useNavigate} from "react-router-dom";

const pollInterval = 300000
const delayTime = 0

const AuthProvider = props => {

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    // const [messages, setMessages] = useState([])
    const [notifications, setNotifications] = useState(0)
    const [menuitems, setMenuitems] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate();


    const [userFromTokenQuery, {startPolling, stopPolling}] = useLazyQuery(USER_FROM_TOKEN_QUERY, {
        fetchPolicy: "network-only",
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            console.log('onError - userFromTokenQuery')
            clearData()
            delay(delayTime).then(setLoading(false))
            stopPolling()
        },
        onCompleted: (d) => {
            console.log('onCompleted - userFromTokenQuery')

            if (d.me) {
                console.log('setting user')
                setUser(d.me)
                setMenuitems(d.menuitemsList)
//                setMessages(d.userFromToken.messages)
                setNotifications(d.notificationCount)
            } else {
                clearData()
                stopPolling()
                //enqueueSnackbar("Sessie beëindigd. U bent uitgelogd.")
                //navigate("/")
            }
            delay(delayTime).then(setLoading(false))
        },
        variables: {},
        pollInterval: pollInterval
    });

    const [userLogoutMutation] = useMutation(USER_LOGOUT_MUTATION, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            stopPolling()
            setLoading(false)
        },
        onCompleted: (d) => {
            clearData()
            stopPolling()
            delay(delayTime).then(setLoading(false))
        },
    });


    const [userLoginMutation] = useMutation(USER_LOGIN_MUTATION, {
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
            stopPolling()
            setLoading(false)
        },
        onCompleted: (d) => {
            enqueueSnackbar("Succesvol ingelogd", {variant: "success"})
            setUser(d.userLogin.user)
            setMenuitems(d.userLogin.menuitems)
            //setMessages(d.userLogin.messages)
            setNotifications(d.userLogin.notificationCount)
            startPolling(pollInterval)
            delay(delayTime).then(setLoading(false))
        },
    });

    const [userLoginLike] = useMutation(USER_LOGIN_LIKE_MUTATION, {
        onCompleted: (d) => {
            enqueueSnackbar('U bent ingelogd als ' + niceUserName(d.userLoginLike.user), {variant: "success"})
            setUser(d.userLoginLike.user)
            setMenuitems(d.userLoginLike.menuitems)
            //setMessages(d.userLoginLike.messages)
            setNotifications(d.userLoginLike.notificationCount)
            startPolling(pollInterval)
            delay(delayTime).then(setLoading(false))
            navigate("/")
        },
        onError: (apolloError) => {
            errorHandler(apolloError, enqueueSnackbar)
        },
    })

    useEffect(() => {
        // vervangt onMountComponent
        console.log('useEffect AuthProvider')
        setLoading(true)
        updateContextFromToken()
    }, []);


    const clearData = () => {
        setUser({})
        //setMessages([])
        setMenuitems([])
        setNotifications(0)
        props.client.resetStore()
    }

    const login = (data) => {
        setLoading(true)
        userLoginMutation(data)
    }

    const logout = async () => {
        setLoading(true)
        await userLogoutMutation()
    };

    const userLoggedIn = () => {
        console.log("testing user")
        return user.hasOwnProperty("id")
    }
    const isUser = (testUserId) => {
        return testUserId === user?.id
    }
    const isInUsers = (testUserIds) => {
        for (let testUserId of testUserIds) {
            if (isUser(testUserId)) {
                return true
            }
        }
        return false
    }

    const userInProfile = (profile_ids) => {
        if (user.hasOwnProperty('profiles')) {
            const testIds = getProfileIdsActionname(profile_ids)
            for (let testProfile of testIds) {
                for (let profile of user.profiles) {
                    if (profile.id === testProfile) return true
                }
            }
        }
        return false
    }

    const getProfileIdsActionname = ids => {
        if (typeof ids === 'string') {
            if (ids === 'AGENDA_EDITORS') return ['ckher34o70002xa1bwp6ca5eq', 'ckher34o70008xa1b4nmhktt8', 'ckher34o70001xa1bcuwtq3as', 'ckher34o70004xa1b213ukwtm']
            return []
        } else {
            return ids
        }


    }


    const updateContextFromToken = async () => {
        setLoading(true)
        console.log('updateContextFromToken')
        await userFromTokenQuery()
    }


    return (
        <AuthContext.Provider value={{
            user: user,
            //messages: messages,
            menuitems: menuitems,
            notifications: notifications,
            loading: loading,
            login: login,
            logout: logout,
            userInProfile: userInProfile,
            isUser: isUser,
            isInUsers: isInUsers,
            userLoggedIn: userLoggedIn,
            userLoginLike: userLoginLike
        }}>
            {props.children}
        </AuthContext.Provider>
    )

}

export default withApollo(AuthProvider)