import {createContext} from 'react';


const AuthContext = createContext({
    // user: {},
    // messages: [],
    // notifications: 0,
    // menuitems: [],
    // loading: true,
    // login: () => {
    // },
    // logout: () => {
    // },
    // userInProfile: (profileIds) => {
    // },
    // isUser: (testUserId) => {
    // },
    // isInUsers: (testUserIds) => {
    // },

});

export default AuthContext

