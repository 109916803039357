import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import Icon from "../../Icons/Icon";
import AuthContext from "../Context/AuthContext";
import {Box, Collapse, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";

var _ = require('lodash');


const classes = {
    nested: {
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: (theme) => theme.spacing(4),
    },
    notnested: {
        paddingTop: '2px',
        paddingBottom: '2px',
    }
}

const UserMenuList = props => {

    const [menuOpen, setMenuOpen] = useState({'': false, admin: false, types: false})

    const handleClick = key => {
        setMenuOpen({...menuOpen, ...{[key]: !menuOpen[key]}})
    }

    const userContext = useContext(AuthContext);

    const menuitemList = _.groupBy(_.sortBy(userContext.menuitems, ['subgroup', 'title']), item => {
        return item.subgroup
    });

    const keys = _.keys(menuitemList)


    return (
        <List dense>
            {keys.map((key, index1) => {
                return (
                    <Box key={index1}>
                        {key && <Box>
                            <ListItem
                                button
                                sx={classes.notnested}
                                onClick={() => handleClick(key)}
                            >
                                <ListItemIcon>
                                    <Icon name={"menu"}/>
                                </ListItemIcon>
                                <ListItemText primary={key}/>
                                {menuOpen[key] ? <Icon name={"chevronUp"}/> : <Icon name={"chevronDown"}/>}
                            </ListItem>
                            <Collapse in={menuOpen[key]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense>
                                    {menuitemList[key].map((menuitem, index2) => (
                                        <BaseListitem menuitem={menuitem} nested={key} key={index2}/>
                                    ))}
                                </List>
                            </Collapse>
                        </Box>}
                        {!key && <Box>{menuitemList[key].map((menuitem, index2) => (
                            <BaseListitem menuitem={menuitem} nested={key} key={index2}/>
                        ))}</Box>}
                    </Box>
                )

                //todo icons for listitem in db

            })}
        </List>
    )
}

export default UserMenuList


const BaseListitem = props => {


    const getIcon = () => {
        return props.menuitem.icon !== '' ? props.menuitem.icon : 'menu'
    }

    return (
        <ListItem
            button
            sx={props.nested ? classes.nested : classes.notnested}
            component={Link}
            to={props.menuitem.link}
            key={props.menuitem.id}
        >
            <ListItemIcon><Icon name={getIcon()}/></ListItemIcon>

            <ListItemText
                primary={props.menuitem.title}
                primaryTypographyProps={{sx: classes.menuText}}
            />
        </ListItem>
    )
}